module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tohar Engineering","short_name":"Tohar Engineering","start_url":"www.tohareng.co.il","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"src/images/logo-big.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a37a4298ea549cb790aabd1eddf04dae"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"392870575462560"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
